<template>
  <div class="menu-form" v-if="!isLoading">
    <Header />
    <Merchant />
    <section class="main">
      <Menu />
    </section>
    <PayButton :buttonName="'Заказать'" :isVisible="true" />
  </div>
</template>

<script>
import Menu from "@/components/blocks/Menu/index.vue";
import PayButton from "@/components/blocks/PayButton/index.vue";
import getMenuInfo from "@/mixins/getMenuInfo";
import setRoute from "@/mixins/setRoute";

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Menu,
    PayButton,
  },
  created() {
    localStorage.setItem(
      "templateId",
      JSON.stringify(this.$route.query.template)
    );
  },
};
</script>
